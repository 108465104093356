/**
 * converts carousel (Contentful?) data to
 * urls for Segment tracking / consumption
 * via Router events
 */
const contentTilesToPromoUrls = (data) => {
  /** @type {{[url: string]: any}} urls  */
  const urls = {};

  if (!data || !data?.contentTilesData) {
    return urls;
  }

  const tileData = data.contentTilesData;

  // eslint-disable-next-line no-restricted-syntax
  for (const k in tileData) {
    if (tileData.hasOwnProperty(k)) {
      const tileRow = tileData[k] || [];
      tileRow?.forEach((tile) => {
        const { link1Url = '', link2Url = '' } = tile || {};
        if (link1Url) {
          urls[link1Url] = tile;
        }
        if (link2Url) {
          urls[link2Url] = tile;
        }
      });
    }
  }

  return urls;
};
export default contentTilesToPromoUrls;
