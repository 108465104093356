import React from 'react';
import DynamicLandingPage from '../dynamicLandingPage';
import { DynamicLandingPgData } from '../../types/DynamicLandingPageReducer';

const HomepageDLP = ({
  homepageDLPData,
}: {
  homepageDLPData: DynamicLandingPgData;
}): React.ReactElement => {
  return (
    <>
      <DynamicLandingPage dynamicLandingPgData={homepageDLPData} />
    </>
  );
};

export default HomepageDLP;
