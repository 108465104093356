import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { get } from 'lodash';
import HomepageDLP from '../components/homepage';
import contentTilesToPromoUrls from '../utilities/segment/mapper/contentTilesToPromoUrls';
import events, { EVENTS } from '../utilities/segment/events';
import { pageViewedMapper } from '../utilities/segment/mapper/pageViewed';
import {
  loadCarouselData,
  loadHomePageData,
  loadHomePageDLPData,
} from '../actions/homepagePromoActions';
import { setLoader } from '../actions';
import { wrapper } from '../store';
import MetaTags from '../components/metaTags';
import dispatchHelper from '../components/dispatchHelper';
import {
  DynamicLandingPgData,
  ShopState,
  Tags,
} from '../types/Store';

export default function Index(): React.ReactElement {
  const showLoader = useSelector(
    (state: ShopState) => state.headerFooterReducer.showLoader,
  );
  const user = useSelector(
    (state: ShopState) => state.cartReducer.user,
  );
  const userCheck = useSelector(
    (state: ShopState) => state.cartReducer.userCheck,
  );
  const promoData = useSelector(
    (state: ShopState) => state.homepagePromoReducer,
  );
  const homepageDLPData = useSelector(
    (state: ShopState) => state.homepagePromoReducer.homepageDLPData,
  );
  const dispatch = useDispatch();
  const router = useRouter();
  const tileLinks = contentTilesToPromoUrls(promoData || {});

  useEffect(() => {
    if (showLoader) {
      dispatch(setLoader(false));
    }
    const handleSegment = (url: string): void => {
      const linkData: any = get(
        promoData || {},
        'heroData.ctaButtons',
        [],
      ).find((data: any) => data?.ctaLink === url);

      if (linkData) {
        events.emit(EVENTS.promoClicked, {
          creative: null,
          name: linkData.title || 'Unknown promo',
          position: 'top of homepage',
          promotion_id: 0,
        });
      }

      if (tileLinks[url]) {
        events.emit(EVENTS.promoClicked, {
          creative: null,
          name: tileLinks[url]?.header || 'Unknown promo',
          position: 'promo block',
          promotion_id: 0,
        });
      }
    };
    router.events.on('routeChangeStart', handleSegment);
    return (): void => {
      router.events.off('routeChangeStart', handleSegment);
    };
  }, []);

  useEffect(() => {
    if (userCheck) {
      events.emit(
        EVENTS.pageViewed,
        pageViewedMapper({
          hasRecommendation: 0,
          userID: user?.id,
          name: 'Homepage',
        }),
      );
    }
  }, [userCheck]);

  return (
    <>
      <MetaTags tags={homepageDLPData?.metaData as Partial<Tags>} />
      {homepageDLPData && (
        <HomepageDLP
          // TO DO: Fix this type inconsistences
          homepageDLPData={homepageDLPData as DynamicLandingPgData}
        />
      )}
    </>
  );
}

const asyncDispatch: any = dispatchHelper(
  [loadHomePageDLPData, loadHomePageData, loadCarouselData],
  false,
  'static',
  process.env.NEXT_PUBLIC_STATIC_REGEN_HOMEPAGE_SECONDS ||
    process.env.NEXT_PUBLIC_STATIC_REGEN_SECONDS,
);

export const getStaticProps = wrapper.getStaticProps(asyncDispatch);
